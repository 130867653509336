import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './componets/Home'
import Portfolio from './componets/Portfolio/Portfolio'
import Services from './componets/Our Services/Services'
import About from './componets/About Us/About'
import Story from './componets/Our Story/Story'
import Talk from './componets/Talk To Us/Talk'
import { AboutSection, InfinateScroller, SectionAbout, SectionFiveOne, TalkyOne } from './componets';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/portfolio' element={<Portfolio />}   />
        <Route path='/services' element={<Services />}   />
        <Route path='/about' element={<About />}   />
        <Route path='/story' element={<Story />}   />
        <Route path='/talk' element={<Talk/>}   />
        <Route path='/path' element={<TalkyOne />}   />
      </Routes>
    </div>
  );
}

export default App;
