import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './sectionfive.module.css';

const services = [
    {
      name: 'Network Security:',
      quote:
        'We implement robust network security measures, including firewalls, intrusion detection and prevention systems (IDS/IPS), and vulnerability assessments to shield your network from unauthorized access.',
    },
    {
      name: 'Endpoint Protection:',
      quote:
        'We secure your devices, including desktops, laptops, and mobile devices, with advanced endpoint protection software to detect and prevent malware, ransomware, and other threats.',
    },
    {
      name: 'Security Awareness Training:',
      quote:
        'We empower your employees to become the first line of defence against cyberattacks by providing comprehensive security awareness training programs.',
    },
    {
      name: 'Security Incident Response:',
      quote:
        'In the event of a cyberattack, we have a proven security incident response plan to minimize damage, contain the breach, and restore operations efficiently.',
    },
    {
      name: 'Cloud Security:',
      quote:
        'Secure your cloud environments with our comprehensive cloud security solutions, including encryption, access management, and continuous monitoring.',
    },
    {
      name: 'Data Protection:',
      quote:
        'Protect your critical business data with our advanced data protection strategies, ensuring confidentiality, integrity, and availability.',
    },
  ];

const SectionFiveOne = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "2%",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "2%",
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "5%",
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "5%",
                }
            }
        ]
    };

    return (
        <div className="w-full flex justify-center my-[9%] mb-[-2%]">
            <div className="w-3/4 m-auto mt-8 mb-8">
                <Slider {...settings}>
                    {services.map((service, index) => (
                        <div key={index} className="mx-2 my-2">
                            <div className={`flex flex-col justify-center items-center gap-4 p-4 ${styles.card}`}>
                                <h1 className="text-xl font-semibold">{service.name}</h1>
                                <p>{service.quote}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default SectionFiveOne;
