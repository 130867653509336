import React from 'react'

const Portfolio = () => {
  return (
    <section id='Portfolio Page'>
      Portfolio
    </section>
  )
}

export default Portfolio
