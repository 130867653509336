import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import SectionStory from './SectionStory'

const Story = () => {
  return (
    <section id='Our Story Page'>
      <Navbar />
      <SectionStory />
      <Footer />
    </section>
  )
}

export default Story
